<template>
    <div class="main-container">
        <div class="coming-soon">
            <p>Coming soon...</p>
        </div>
    </div>
</template>

<script>
export default {
    name : "Conferences"
}
</script>

<style lang="scss" scoped>
    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        font-size:40px;
        color:$main-color;
        font-weight:400;
    }
</style>